<template>
  <div class="carDisinfectManagement">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="车牌号码">
            <el-input v-model="form.carNum" placeholder="请输入车牌号码" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="add()">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
          :rules="addFormRules"
        >
          <el-table-column prop="carNum" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="disinfectOrgan" label="消毒机构" align="center"></el-table-column>
          <el-table-column prop="disinfectTime" label="消毒时间" align="center"></el-table-column>
          <el-table-column prop="disinfectMoney" label="消毒费用" align="center"></el-table-column>
          <el-table-column prop="disinfectImg" label="消毒发票" align="center">
            <template slot-scope="scope">
                <div v-if="scope.row.disinfectImg == undefined">暂未上传</div>
                <div @click="lookImg(scope.row.disinfectImg)" v-else>点击查看</div>
             </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <!-- <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[1, 10, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
        <span slot="title" class="dialog-Title">车辆消毒详情</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="selectFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车牌号码" prop="carNum">
              <el-select
                v-model="selectForm.carNum"
                filterable
                placeholder="请选择车牌号码"
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.carNum"
                  :value="item.carNum"
                  v-for="(item, index) in carNumList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消毒机构" prop="disinfectOrgan">
              <el-input v-model="selectForm.disinfectOrgan" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="消毒时间" prop="disinfectTime">
              <el-date-picker v-model="selectForm.disinfectTime" type="datetime" placeholder="选择消毒时间" :disabled="isDisabled"></el-date-picker>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="消毒费用" prop="disinfectMoney">
              <el-input
                v-model="selectForm.disinfectMoney"
                autocomplete="off"
                :disabled="isDisabled"
                v-input-limit

              ></el-input>
              <!-- onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))" -->

            </el-form-item>
          </el-row>
          <!-- <el-row>
             <el-form-item label="消毒发票" prop="disinfectImg">
               <input ref="editUploadRef" type="file" id="Updateimage" lay-verify="required" @change="toBase64(1)" accept="image/jpeg, image/png, image/jpg" />
               <img @click="closeImg(1,'editUploadRef')" v-show="selectForm.disinfectImg" class="close" src="@/images/关 闭.png" alt="">

            </el-form-item>
          </el-row> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加车辆消毒信息</span>
        <el-form :model="addForm" inline ref="addForm" class="addForm" :rules="addFormRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车牌号码" prop="carNum">
              <el-select v-model="addForm.carNum" filterable placeholder="请选择车牌号码" clearable>
                <el-option
                  :label="item.carNum"
                  :value="item.carNum"
                  v-for="(item, index) in carNumList"
                  :key="index"
                ></el-option>
                 <!-- <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="消毒机构" prop="disinfectOrgan">
              <el-input v-model="addForm.disinfectOrgan" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="消毒时间" prop="disinfectTime">
              <el-date-picker v-model="addForm.disinfectTime" type="datetime" placeholder="选择消毒时间"></el-date-picker>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="消毒费用" prop="disinfectMoney">
              <el-input
              v-input-limit
 v-model="addForm.disinfectMoney" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
          <!-- <el-row>
             <el-form-item label="消毒发票" prop="disinfectImg">
              <input ref="addUploadRef" type="file" id="Updateimage" lay-verify="required" @change="toBase64(0)" accept="image/jpeg, image/png, image/jpg" />
              <img @click="closeImg(0,'addUploadRef')" v-show="addForm.disinfectImg" class="close" src="@/images/关 闭.png" alt="">
            </el-form-item>

          </el-row> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
    <el-dialog title="提示" :visible.sync="imgDialogVisible" width="50%" class="invoiceImg">
      <img :src="invoiceImg" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="imgDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'carDisinfectManagement',
  components: {
  },
  data () {
    return {
      invoiceImg: '',
      imgDialogVisible: false, // 发票显示隐藏
      tabData: [], // 表格数据源
      form: { carNum: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      // 添加表单
      addForm: {
        carNum: '',
        disinfectOrgan: '',
        disinfectTime: '',
        disinfectMoney: '',
        disinfectImg: ''
      },
      // is: true,
      addFormRules: {
        carNum: [
          { required: true, message: '请输入车牌号码', trigger: 'blur' }
        ],
        disinfectOrgan: [
          { required: false, message: '请输入消毒机构', trigger: 'blur' }
        ],
        disinfectTime: [
          { required: true, message: '请输入消毒时间', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        carNum: [
          { required: true, message: '请输入车牌号码', trigger: 'blur' }
        ],
        disinfectOrgan: [
          { required: false, message: '请输入消毒机构', trigger: 'blur' }
        ],
        disinfectTime: [
          { required: true, message: '请输入消毒时间', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '正常', status: 1 }, { label: '停用', status: 2 }],
      carNumList: []

    //   options: [{
    //     value: '选项1',
    //     label: '黄金糕'
    //   }, {
    //     value: '选项2',
    //     label: '双皮奶'
    //   }, {
    //     value: '选项3',
    //     label: '蚵仔煎'
    //   }, {
    //     value: '选项4',
    //     label: '龙须面'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭2'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭3'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭4'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭5'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭6'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭7'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭8'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭9'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭10'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭11'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭12'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭13'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭14'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭15'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭16'
    //   }, {
    //     value: '选项5',
    //     label: '北京烤鸭17'
    //   }],
    //   value: ''
    }
  },
  created () {
    this.getAllCar()
    this.getAllCarNum()
  },
  methods: {
    lookImg (val) {
      this.invoiceImg = val
      this.imgDialogVisible = true
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取车辆消毒信息
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/tInfoVehicleDisinfect/getTInfoVehicleDisinfectList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo
        // "onStatus": '4'
        // "status": 1
      })
      // console.log(res);
      if (res.statusCode !== '200') return this.$message.error('获取车主列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 获取所有车牌
    async getAllCarNum () {
      const { data: res } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleList', {
        onStatus: '4',
        pageSize: 9999,
        pageNo: 1
      })
      // console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取车牌失败')
      this.carNumList = res.data

      // this.carTotal = +res.pageBean.pageDataCount
    },
    add () {
      this.getAllCarNum()
      this.addDialogFormVisible = true
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.carNum === '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        this.paging.PageNo = 1
        const { data: res } = await this.$http.post('/pmService/tInfoVehicleDisinfect/getTInfoVehicleDisinfectList', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          carNum: this.form.carNum
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data.records
        this.total = +res.data.total
      }
    },
    closeImg (type, ref) {
      this.$refs[ref].value = ''
      type == 0 ? this.addForm.disinfectImg = '' : this.selectForm.disinfectImg = ''
    },
    toBase64 (type) {
      var that = this
      var file = document.querySelector('input[type=file]').files[0]
      var reader = new FileReader()
      reader.onloadend = function () {
        type == 0 ? that.addForm.disinfectImg = reader.result : that.selectForm.disinfectImg = reader.result
      }
      console.log(file, 'file')
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.carNum}`, '删除车辆消毒记录', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data: res } = await this.$http.post('/pmService/tInfoVehicleDisinfect/deleteTInfoVehicleDisinfect', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          this.selectForm.disinfectTime = this.formatTime(this.selectForm.disinfectTime, 'yyyy-MM-dd hh:mm:ss')
          // 进行编辑操作
          const { data: res } = await this.$http.post('/pmService/tInfoVehicleDisinfect/updateTInfoVehicleDisinfect', JSON.parse(JSON.stringify(this.selectForm)))
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          this.selectForm.disinfectImg = ''
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 格式化时间
    formatTime (date, fmt) {
      var date = new Date(date)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          var str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
        }
      }
      return fmt
    },
    // 添加机构--确定按钮
    addSure () {
      console.log(this.addForm, 'this.addForm')

      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        // 调接口，发送添加客户请求
        this.addForm.disinfectTime = this.formatTime(this.addForm.disinfectTime, 'yyyy-MM-dd hh:mm:ss')
        this.addForm.createBy = sessionStorage.getItem('userId')

        const { data: res } = await this.$http.post('/pmService/tInfoVehicleDisinfect/addTInfoVehicleDisinfect', JSON.parse(JSON.stringify(this.addForm)))
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        this.addForm.disinfectImg = ''
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.carDisinfectManagement {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.invoiceImg img {
    width: 100%;
}
.close{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
</style>
